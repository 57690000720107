@font-face {
  font-family: "Darker Grotesque";
  src: url("../assets/DarkerGrotesque-Regular.ttf");
}
@font-face {
  font-family: "Mirande";
  src: url("../assets/Mirande.ttf");
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #fdfdfd;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow: hidden;
}

.header h1 {
  font-family: "Mirande";
  color: #164b66;
  position: relative;
  font-size: 300%;
  right: 40px;
}
.header span {
  font-family: "Darker Grotesque";
  color: #003f82;
  position: relative;
  font-size: 40%;
  right: -10px;
  bottom: 5px;
}
.main-image {
  width: 100%;
  height: 10rem;
  z-index: 0;
  overflow: hidden;
}

.main-image img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #e3e4fa;
  border: 1px solid #e3e4fa;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #ae82cc;
}

.nav {
  display: flex;
}

.nav div {
  text-decoration: none;
  padding: 1rem;
  margin: 5px;
  color: black;
}
