.actions button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin-right: 15px;
}
.actions {
  margin: 0 auto;
  text-align: center;
}

.actions button:hover {
  background-color: #ebf4fa;
  border-color: black;
}
.container {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  animation: appear 0.8s ease-out forwards;
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}
.container .overview {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.wrapper {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
/* .container {
  margin: 3rem auto;
  width: 95%;
  background-color: #e3e3e3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  height: 500px;
  animation: login-appear 0.8s ease-out forwards;
  overflow-y: auto;
  overflow-x: none;
} */

ul {
  list-style-type: none;
}
table {
  margin: 0 auto;
  padding: 10px;
  width: 1000px;
  border-collapse: collapse;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: #eaeaea;
}
td {
  height: 55px;
  padding: 10px;
}
.trow:nth-child(even) {
  background-color: #d7d7d7;
}
th {
  padding: 5px;
}
.trow:hover {
  background-color: #cfcfcf;
  color: black;
  /* cursor: pointer; */
  transition: 0.1s ease;
}

li {
  list-style-type: none;
  text-align: left;
}

.input {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}
.input2 {
  height: 40px;
  width: 100px;
  padding: 10px;
  border-radius: 5px;
}

.input2:hover {
  cursor: pointer;
  background-color: #d5d5d5;
}

.button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}
.content {
  height: 450px;
  overflow-y: auto;
  padding: 10px;
}

.red {
  color: red;
}

.hover:hover {
  cursor: pointer;
}

.scanIn:nth-child(even) {
  background-color: #dce8d0;
}
.scanIn:nth-child(odd) {
  background-color: #d1dcc6;
}

.scanOut:nth-child(even) {
  background-color: #dcc6c6;
}
.scanOut:nth-child(odd) {
  background-color: #eebfbf;
}

.smallText {
  font-size: 50%;
  margin-right: 4px;
  margin-left: 7px;
}

.scanChecker {
  position: relative;
  display: inline-block;
  border: 2px solid black;
  margin-bottom: -150px;
  bottom: -10px;
  height: 50px;
  max-width: 200px;
  min-width: 100px;
  width: 50%;
  right: 400px;
  margin-right: 40px;
}

.formInput {
  display: inline;
}

.checkboxHidden {
  display: none;
  font-size: 50%;
  margin-left: 10px;
}

.resetText {
  font-size: 50%;
}
