.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 25px;
  background-color: #737ca1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: white;
  animation: login-appear 0.8s ease-out forwards;
}

.auth h1 {
  text-align: center;
  color: white;
}

.auth .picture {
  margin: 0 auto;
}
.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: #f1e1fc;
  color: black;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #e3e4fa;
  border: 1px solid #e3e4fa;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #ae82cc;
}

.register {
  margin-top: 1rem;
  font-size: 90%;
}

.picture {
  height: 170px;
  border: 1px solid black;
  width: 170px;
  border-radius: 100px;
  overflow: hidden;
}

.picture a {
  position: absolute;
  top: 167px;
  left: 184px;
}

.picture a:hover {
  cursor: pointer;
}

@keyframes login-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
