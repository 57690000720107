@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: "Titillium Web", sans-serif;
}

body {
  margin: 0;
  background-color: #fdfdfd;
}

.HomePage_container__1Ql6P {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  /* animation: appear 0.8s ease-out forwards; */
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}

.HomePage_container__1Ql6P .HomePage_overview__wyoeC {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.HomePage_wrapper__3mWG_ {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.HomePage_content__1VVgC {
  background-color: #ebebeb;
}
.HomePage_individual__ICyXB {
  padding: 10px;
  width: 200px;
  height: 70px;
  border-radius: 10px;
  background-color: #e1eaec;
  margin-left: 30px;
  display: inline-block;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.HomePage_quantity__2gVeo {
  position: relative;
  display: flex;
  left: 77px;
  margin: 0 auto;
}
.HomePage_flex__r-HvH {
  display: grid;
  grid-template-columns: 50% 49%;
  grid-gap: 1em;
  padding: 15px;
}
.HomePage_flex__r-HvH div {
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes HomePage_appear__pXHX0 {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes HomePage_appear__pXHX0 {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.HomePage_table__1bffY {
  width: 102%;
}

.HomePage_table__1bffY td {
  padding: 5px;
  width: 150px;
}
.HomePage_tableMain__1l2ur {
  width: 100%;
}
.HomePage_tableMain__1l2ur th {
  padding: 5px;
  width: 300px;
}

.HomePage_overflowBox__1bW7m {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 280px;
  max-height: 280px;
}

.HomePage_input__1zNJT {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}

.HomePage_overview__wyoeC select {
  position: relative;
  left: 5px;
  bottom: 5px;
  padding: 5px;
}

@media only screen and (max-width: 1366px) {
  .HomePage_flex__r-HvH {
    display: grid;
    overflow-x: hidden;
  }
  .HomePage_individual__ICyXB {
    padding: 10px;
    width: 200px;
    height: 70px;
    border-radius: 10px;
    background-color: #e1eaec;
    left: -10px;
    position: relative;
    display: inline-block;
    text-align: center;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  .HomePage_quantity__2gVeo {
    position: relative;
    left: 80px;
    margin: 0 auto;
  }

  .HomePage_wrapper__3mWG_ {
    font-size: 90%;
  }

  .HomePage_input__1zNJT {
    height: 40px;
    width: 70%;
    padding: 10px;
    border-radius: 5px;
  }
}

.HomePage_hover__tjrSl:hover {
  cursor: pointer;
}

.Profile_auth__WVGLs {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 25px;
  background-color: #737ca1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: white;
  -webkit-animation: Profile_login-appear__JPMfw 0.8s ease-out forwards;
          animation: Profile_login-appear__JPMfw 0.8s ease-out forwards;
}

.Profile_auth__WVGLs h1 {
  text-align: center;
  color: white;
}

.Profile_auth__WVGLs .Profile_picture__1CdfK {
  margin: 0 auto;
}
.Profile_control__3KJEG {
  margin-bottom: 0.5rem;
}

.Profile_control__3KJEG label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.Profile_control__3KJEG input {
  font: inherit;
  background-color: #f1e1fc;
  color: black;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.Profile_actions__2Xxpv {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile_actions__2Xxpv button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #e3e4fa;
  border: 1px solid #e3e4fa;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.Profile_actions__2Xxpv button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}

.Profile_actions__2Xxpv .Profile_toggle__yE9Lx {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.Profile_actions__2Xxpv .Profile_toggle__yE9Lx:hover {
  background-color: transparent;
  color: #ae82cc;
}

.Profile_register__2MDXB {
  margin-top: 1rem;
  font-size: 90%;
}

.Profile_picture__1CdfK {
  height: 170px;
  border: 1px solid black;
  width: 170px;
  border-radius: 100px;
  overflow: hidden;
}

.Profile_picture__1CdfK a {
  position: absolute;
  top: 167px;
  left: 184px;
}

.Profile_picture__1CdfK a:hover {
  cursor: pointer;
}

@-webkit-keyframes Profile_login-appear__JPMfw {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Profile_login-appear__JPMfw {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Login_auth__1cu7s {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 25px;
  background-color: #3b3b3b;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: white;
  -webkit-animation: Login_login-appear__1sLGW 0.8s ease-out forwards;
          animation: Login_login-appear__1sLGW 0.8s ease-out forwards;
  margin-top:240px;
}

.Login_auth__1cu7s h1 {
  text-align: center;
  color: white;
}

.Login_control__1jPwp {
  margin-bottom: 0.5rem;
}

.Login_control__1jPwp label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.Login_control__1jPwp input {
  font: inherit;
  background-color: #fdfdfd;
  color: black;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.Login_actions__2cqa5 {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login_actions__2cqa5 button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.Login_actions__2cqa5 button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}

.Login_actions__2cqa5 .Login_toggle__1SKZl {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.Login_actions__2cqa5 .Login_toggle__1SKZl:hover {
  background-color: transparent;
  color: #ae82cc;
}

.Login_register__3CvqU {
  margin-top: 1rem;
  font-size: 90%;
}
@-webkit-keyframes Login_login-appear__1sLGW {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes Login_login-appear__1sLGW {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Login_container__26vjN {
  text-align: center;
}
.Login_container__26vjN table {
  width: 100%;
}

@font-face {
  font-family: "Darker Grotesque";
  src: url(/static/media/DarkerGrotesque-Regular.1f4f2868.ttf);
}
@font-face {
  font-family: "Mirande";
  src: url(/static/media/Mirande.96bff701.ttf);
}

.Header_header___g472 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #fdfdfd;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow: hidden;
}

.Header_header___g472 h1 {
  font-family: "Mirande";
  color: #164b66;
  position: relative;
  font-size: 300%;
  right: 40px;
}
.Header_header___g472 span {
  font-family: "Darker Grotesque";
  color: #003f82;
  position: relative;
  font-size: 40%;
  right: -10px;
  bottom: 5px;
}
.Header_main-image__2Ci25 {
  width: 100%;
  height: 10rem;
  z-index: 0;
  overflow: hidden;
}

.Header_main-image__2Ci25 img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
          transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.Header_actions__1gFyB {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header_actions__1gFyB button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #e3e4fa;
  border: 1px solid #e3e4fa;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.Header_actions__1gFyB button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}

.Header_actions__1gFyB .Header_toggle__2EeNG {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.Header_actions__1gFyB .Header_toggle__2EeNG:hover {
  background-color: transparent;
  color: #ae82cc;
}

.Header_nav__2RLVa {
  display: flex;
}

.Header_nav__2RLVa div {
  text-decoration: none;
  padding: 1rem;
  margin: 5px;
  color: black;
}

.inventory_actions__3EMSs button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin-right: 15px;
}
.inventory_actions__3EMSs {
  margin: 0 auto;
  text-align: center;
}

.inventory_actions__3EMSs button:hover {
  background-color: #ebf4fa;
  border-color: black;
}
.inventory_container__txkOj {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  -webkit-animation: inventory_appear__2Xf93 0.8s ease-out forwards;
          animation: inventory_appear__2Xf93 0.8s ease-out forwards;
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}
.inventory_container__txkOj .inventory_overview__6eWov {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.inventory_wrapper__1SfgH {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
/* .container {
  margin: 3rem auto;
  width: 95%;
  background-color: #e3e3e3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  height: 500px;
  animation: login-appear 0.8s ease-out forwards;
  overflow-y: auto;
  overflow-x: none;
} */

ul {
  list-style-type: none;
}
table {
  margin: 0 auto;
  padding: 10px;
  width: 1000px;
  border-collapse: collapse;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: #eaeaea;
}
td {
  height: 55px;
  padding: 10px;
}
.inventory_trow__2n8nh:nth-child(even) {
  background-color: #d7d7d7;
}
th {
  padding: 5px;
}
.inventory_trow__2n8nh:hover {
  background-color: #cfcfcf;
  color: black;
  /* cursor: pointer; */
  transition: 0.1s ease;
}

li {
  list-style-type: none;
  text-align: left;
}

.inventory_input__1YmAu {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}
.inventory_input2__2fEOG {
  height: 40px;
  width: 100px;
  padding: 10px;
  border-radius: 5px;
}

.inventory_input2__2fEOG:hover {
  cursor: pointer;
  background-color: #d5d5d5;
}

.inventory_button__1u0M8 {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.inventory_button__1u0M8:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}
.inventory_content__3rry6 {
  height: 450px;
  overflow-y: auto;
  padding: 10px;
}

.inventory_red__1IxAP {
  color: red;
}

.inventory_hover__SofuS:hover {
  cursor: pointer;
}

.inventory_scanIn__1VGqj:nth-child(even) {
  background-color: #dce8d0;
}
.inventory_scanIn__1VGqj:nth-child(odd) {
  background-color: #d1dcc6;
}

.inventory_scanOut__P-4Lf:nth-child(even) {
  background-color: #dcc6c6;
}
.inventory_scanOut__P-4Lf:nth-child(odd) {
  background-color: #eebfbf;
}

.inventory_smallText__2-c5A {
  font-size: 50%;
  margin-right: 4px;
  margin-left: 7px;
}

.inventory_scanChecker__18fsH {
  position: relative;
  display: inline-block;
  border: 2px solid black;
  margin-bottom: -150px;
  bottom: -10px;
  height: 50px;
  max-width: 200px;
  min-width: 100px;
  width: 50%;
  right: 400px;
  margin-right: 40px;
}

.inventory_formInput__3ytdc {
  display: inline;
}

.inventory_checkboxHidden__2Swyd {
  display: none;
  font-size: 50%;
  margin-left: 10px;
}

.inventory_resetText__3W6l8 {
  font-size: 50%;
}

.Modal_backdrop__g0s5I {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_container__23TUT {
  margin: 3rem auto;
  width: 95%;
  border-radius: 25px;
  background-color: #737ca1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: white;
  -webkit-animation: Modal_login-appear__2EXQb 0.8s ease-out forwards;
          animation: Modal_login-appear__2EXQb 0.8s ease-out forwards;
}
.Modal_modal__3fLMV {
  position: fixed;
  top: 20vh;
  margin: 20px -160px;
  text-align: center;
  width: 90%;
  height: 700px;
  min-width: 1000px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: Modal_slide-down__1bH_P 300ms ease-out forwards;
          animation: Modal_slide-down__1bH_P 300ms ease-out forwards;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .Modal_modal__3fLMV {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@-webkit-keyframes Modal_slide-down__1bH_P {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__1bH_P {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Admin_auth__1iQR4 {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 25px;
  background-color: #737ca1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: white;
  -webkit-animation: Admin_login-appear__Ld0So 0.8s ease-out forwards;
          animation: Admin_login-appear__Ld0So 0.8s ease-out forwards;
}

.Admin_auth__1iQR4 h1 {
  text-align: center;
  color: white;
}

.Admin_control__1XJOL {
  margin-bottom: 0.5rem;
}

.Admin_control__1XJOL label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.Admin_control__1XJOL input {
  font: inherit;
  background-color: #f1e1fc;
  color: black;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.Admin_actions__1EeHp {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Admin_actions__1EeHp button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #e3e4fa;
  border: 1px solid #e3e4fa;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.Admin_actions__1EeHp button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}

.Admin_actions__1EeHp .Admin_toggle__32bbZ {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.Admin_actions__1EeHp .Admin_toggle__32bbZ:hover {
  background-color: transparent;
  color: #ae82cc;
}

.Admin_register__39whW {
  margin-top: 1rem;
  font-size: 90%;
}
@-webkit-keyframes Admin_login-appear__Ld0So {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes Admin_login-appear__Ld0So {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Sidebar_sidenav__1ASDT {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  background-color: white;
  overflow-x: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  transition: 0.4s ease;
}

.Sidebar_pullButton__3xpEr {
  top: 100px;
  height: 30px;
  left: 12px;
  z-index: 10;
  position: absolute;
  transition: 0.4s ease;
}

.Sidebar_content__2yMx_ {
  position: relative;
  top: 180px;
  transition: 0.4s ease;
}
.Sidebar_content__2yMx_ a {
  position: relative;
  left: 10px;
  color: #164b66;
  transition: 0.4s ease;
}
.Sidebar_content__2yMx_ a:hover {
  color: #a7a7a7;
  height: 100px;
  transition: 0.2s ease;
}
.Sidebar_hideText__2jbQG {
  position: relative;
  padding: 5px;
  top: -7px;
  transition: 0.4s ease;
}

.Sidebar_bar1__-ZSe3,
.Sidebar_bar2__3EPwm,
.Sidebar_bar3__3cqaJ {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  position: fixed;
  transition: 0.3s;
}

.Sidebar_bar2__3EPwm {
  margin-top: 14px;
}
.Sidebar_bar3__3cqaJ {
  margin-top: 23px;
}

.Sidebar_change__3-Y2F .Sidebar_bar1__-ZSe3 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 4px);
}

.Sidebar_change__3-Y2F .Sidebar_bar2__3EPwm {
  opacity: 0;
}

.Sidebar_change__3-Y2F .Sidebar_bar3__3cqaJ {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -4px);
}
.Sidebar_fixed__3GACC {
  position: fixed;
  margin-left: -2px;
}
.Sidebar_icon__3u9L3 {
  display: inline-block;
  cursor: pointer;
}
@media only screen and (max-width: 1090px) {
}

@media only screen and (max-width: 767px) {
}
@media screen and (max-height: 450px) {
  .Sidebar_sidenav__1ASDT {
    padding-top: 15px;
  }
  .Sidebar_sidenav__1ASDT a {
    font-size: 18px;
  }
}

.misc_actions__25Cwy button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin-right: 15px;
}
.misc_actions__25Cwy {
  margin: 0 auto;
  text-align: center;
}

.misc_actions__25Cwy button:hover {
  background-color: #ebf4fa;
  border-color: black;
}
.misc_container__3tyT5 {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  -webkit-animation: misc_appear__3Lu06 0.8s ease-out forwards;
          animation: misc_appear__3Lu06 0.8s ease-out forwards;
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}
.misc_container__3tyT5 .misc_overview__25vDA {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.misc_wrapper__1ysvW {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
/* .container {
    margin: 3rem auto;
    width: 95%;
    background-color: #e3e3e3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    text-align: center;
    color: black;
    height: 500px;
    animation: login-appear 0.8s ease-out forwards;
    overflow-y: auto;
    overflow-x: none;
  } */

ul {
  list-style-type: none;
}
table {
  margin: 0 auto;
  padding: 10px;
  width: 1000px;
  border-collapse: collapse;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: #eaeaea;
}
td {
  height: 55px;
  padding: 10px;
}
.misc_trow__3BiEy:nth-child(even) {
  background-color: #d7d7d7;
}
th {
  padding: 5px;
}
.misc_trow__3BiEy:hover {
  background-color: #cfcfcf;
  color: black;
  cursor: pointer;
  transition: 0.1s ease;
}

li {
  list-style-type: none;
  text-align: left;
}

.misc_input__2obl6 {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}

.misc_button__2D2fC {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.misc_button__2D2fC:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}
.misc_content__7pZgS {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
}

.misc_red__39nko {
  color: red;
}

.misc_inputS__2tvHE {
  height: 40px;
  width: 100px;
  padding: 10px;
  border-radius: 5px;
}

.misc_gridBox__1RiSC {
  display: grid;
  grid-template-columns: 10% 20% 20% 20%;
  grid-gap: 5.4em;
}

.misc_gridBox__1RiSC div {
  height: 200px;
  border: 1px solid black;
}

.custom_actions__2dDjO button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin-right: 15px;
}
.custom_actions__2dDjO {
  margin: 0 auto;
  text-align: center;
}

.custom_actions__2dDjO button:hover {
  background-color: #ebf4fa;
  border-color: black;
}
.custom_container__20oV6 {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  -webkit-animation: custom_appear__3d7ZV 0.8s ease-out forwards;
          animation: custom_appear__3d7ZV 0.8s ease-out forwards;
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}
.custom_container__20oV6 .custom_overview__38kO1 {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.custom_wrapper__2J05s {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
/* .container {
    margin: 3rem auto;
    width: 95%;
    background-color: #e3e3e3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    text-align: center;
    color: black;
    height: 500px;
    animation: login-appear 0.8s ease-out forwards;
    overflow-y: auto;
    overflow-x: none;
  } */

.custom_input__2puRk {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}

.custom_button__1U3Pq {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.custom_button__1U3Pq:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}
.custom_content__x-xGm {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 40% 59%;
  grid-gap: 1em;
  margin: 0 auto;
  text-align: center;
  overflow-x: hidden;
}

.custom_red__hmmFH {
  color: red;
}

.custom_information__F1ijM {
  display: grid;
  text-align: center;
  margin: 0 auto;
}
.custom_gridContent__3wH_4 {
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

table {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

table > tr {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.custom_trow__11Q0l:nth-child(even) {
}

.custom_trow__11Q0l:hover {
  cursor: pointer;
  background-color: #D5D5D5;
}

