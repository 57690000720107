.sidenav {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  background-color: white;
  overflow-x: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  transition: 0.4s ease;
}

.pullButton {
  top: 100px;
  height: 30px;
  left: 12px;
  z-index: 10;
  position: absolute;
  transition: 0.4s ease;
}

.content {
  position: relative;
  top: 180px;
  transition: 0.4s ease;
}
.content a {
  position: relative;
  left: 10px;
  color: #164b66;
  transition: 0.4s ease;
}
.content a:hover {
  color: #a7a7a7;
  height: 100px;
  transition: 0.2s ease;
}
.hideText {
  position: relative;
  padding: 5px;
  top: -7px;
  transition: 0.4s ease;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  position: fixed;
  transition: 0.3s;
}

.bar2 {
  margin-top: 14px;
}
.bar3 {
  margin-top: 23px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 4px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -4px);
}
.fixed {
  position: fixed;
  margin-left: -2px;
}
.icon {
  display: inline-block;
  cursor: pointer;
}
@media only screen and (max-width: 1090px) {
}

@media only screen and (max-width: 767px) {
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
