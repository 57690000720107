.container {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  /* animation: appear 0.8s ease-out forwards; */
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}

.container .overview {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.wrapper {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.content {
  background-color: #ebebeb;
}
.individual {
  padding: 10px;
  width: 200px;
  height: 70px;
  border-radius: 10px;
  background-color: #e1eaec;
  margin-left: 30px;
  display: inline-block;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.quantity {
  position: relative;
  display: flex;
  left: 77px;
  margin: 0 auto;
}
.flex {
  display: grid;
  grid-template-columns: 50% 49%;
  grid-gap: 1em;
  padding: 15px;
}
.flex div {
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.table {
  width: 102%;
}

.table td {
  padding: 5px;
  width: 150px;
}
.tableMain {
  width: 100%;
}
.tableMain th {
  padding: 5px;
  width: 300px;
}

.overflowBox {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 280px;
  max-height: 280px;
}

.input {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}

.overview select {
  position: relative;
  left: 5px;
  bottom: 5px;
  padding: 5px;
}

@media only screen and (max-width: 1366px) {
  .flex {
    display: grid;
    overflow-x: hidden;
  }
  .individual {
    padding: 10px;
    width: 200px;
    height: 70px;
    border-radius: 10px;
    background-color: #e1eaec;
    left: -10px;
    position: relative;
    display: inline-block;
    text-align: center;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  .quantity {
    position: relative;
    left: 80px;
    margin: 0 auto;
  }

  .wrapper {
    font-size: 90%;
  }

  .input {
    height: 40px;
    width: 70%;
    padding: 10px;
    border-radius: 5px;
  }
}

.hover:hover {
  cursor: pointer;
}
