.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.container {
  margin: 3rem auto;
  width: 95%;
  border-radius: 25px;
  background-color: #737ca1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: white;
  animation: login-appear 0.8s ease-out forwards;
}
.modal {
  position: fixed;
  top: 20vh;
  margin: 20px -160px;
  text-align: center;
  width: 90%;
  height: 700px;
  min-width: 1000px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
