@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Titillium Web", sans-serif;
}

body {
  margin: 0;
  background-color: #fdfdfd;
}
