.actions button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin-right: 15px;
}
.actions {
  margin: 0 auto;
  text-align: center;
}

.actions button:hover {
  background-color: #ebf4fa;
  border-color: black;
}
.container {
  margin-left: 50px;
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  text-align: center;
  color: black;
  animation: appear 0.8s ease-out forwards;
  top: 80px;
  position: relative;
  min-height: 850px;
  height: 100%;
  transition: 0.4s ease;
}
.container .overview {
  text-align: left;
  display: block;
  color: black;
  font-size: 200%;
  font-weight: bold;
}

.wrapper {
  background-color: white;
  min-height: 120px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
/* .container {
    margin: 3rem auto;
    width: 95%;
    background-color: #e3e3e3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    text-align: center;
    color: black;
    height: 500px;
    animation: login-appear 0.8s ease-out forwards;
    overflow-y: auto;
    overflow-x: none;
  } */

.input {
  height: 40px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}

.button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: #fdfdfd;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.button:hover {
  background-color: #ebf4fa;
  border-color: #ebf4fa;
}
.content {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 40% 59%;
  grid-gap: 1em;
  margin: 0 auto;
  text-align: center;
  overflow-x: hidden;
}

.red {
  color: red;
}

.information {
  display: grid;
  text-align: center;
  margin: 0 auto;
}
.gridContent {
  background-color: #ebebeb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

table {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

table > tr {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.trow:nth-child(even) {
}

.trow:hover {
  cursor: pointer;
  background-color: #D5D5D5;
}
